import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import FaqAccordion from './FaqAccordion';

const FaqModal = ({ isOpen, onClose }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>FAQ</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <FaqAccordion />
      </ModalBody>

      <ModalFooter>
        <Button colorScheme="main" mr={3} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

FaqModal.propTypes = { isOpen: PropTypes.bool, onClose: PropTypes.func };

export default FaqModal;
