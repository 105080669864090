import React from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';

const FaqAccordion = () => (
  <Accordion>
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            What is Midwap Capital?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        Midway Capital is a Real Estate Investment Club created to help our
        friends and family of current members invest in their first few deals in
        alternative assets. Because of our personal connection to each member, I
        try to keep the fees and expenses extremely low as the savings are
        passed back directly to all of us.
      </AccordionPanel>
    </AccordionItem>

    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            Should I join the fund?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        Real estate is an illiquid asset and harder to sell compared to stocks
        on an open exchange. Since the majority of our deals have a five-year
        cycle I believe that 5 years is the absolute minimum time horizon for
        anyone interested in joining.
      </AccordionPanel>
    </AccordionItem>

    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            How can I join the fund?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        We are currently accepting only friends and family in USA for now. As I
        obtain the appropriate financial licenses and scale-up we may be able to
        expand the circle in the near future.
      </AccordionPanel>
    </AccordionItem>

    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            What is the minimum investment?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        The minimum investment in the fund is currently $30K with $5K
        increments. The majority of funds we invest in would have an individual
        minimum of at least $60K with upwards to $250K-300K to receive the best
        rates.
      </AccordionPanel>
    </AccordionItem>

    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            How often do you communicate with investors?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        Quarterly reports are shared with investors for each property that we
        are invested in. I also send regular emails for upcoming prospective
        investments as well. Current members also have access to my calendar and
        book 1-on-1s with me.
      </AccordionPanel>
    </AccordionItem>

    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            How often do you send distributions?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        Distributions are sent every quarter by ACH for every fund you are
        invested in.
      </AccordionPanel>
    </AccordionItem>

    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            What are the fund&apos;s returns?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        Investors will be able to select which funds they do or do not want to
        participate in based on their risk tolerance and time horizon. Since the
        projected returns vary wildly based on location, asset class, and
        overall size it is hard to provide an all-encompassing number without
        misrepresentation. But for some real numbers, two examples (in the Southwest) that I&apos;m
        currently invested in are:
        <UnorderedList>
          <ListItem>
            <Link
              color="#6C63FF"
              href="https://www.investopedia.com/terms/i/irr.asp"
            >
              {'IRR '}
            </Link>
            of 19-21% with
            <Link
              color="#6C63FF"
              href="https://www.investopedia.com/terms/c/cashoncashreturn.asp"
            >
              {' CoC '}
            </Link>
            of 8-9%
          </ListItem>
          <ListItem>
            <Link
              color="#6C63FF"
              href="https://www.investopedia.com/terms/i/irr.asp"
            >
              {'IRR '}
            </Link>
            of 15-16% with
            <Link
              color="#6C63FF"
              href="https://www.investopedia.com/terms/c/cashoncashreturn.asp"
            >
              {' CoC '}
            </Link>
            of 11-12%
          </ListItem>
        </UnorderedList>
      </AccordionPanel>
    </AccordionItem>

    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            What are the fees?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        Each fund will have all fees mentioned upfront. The current fund has a
        straightforward 1.00% fee on all distributions. There is no Asset Under
        Management (AUM) fee.
      </AccordionPanel>
    </AccordionItem>

    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            What are the fund expenses?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        Although I&apos;ve tried my best to reduce the costs there are some fees
        that are unavoidable such as LLC filing fees, legal consultations, and
        accounting fees. These expenses will be listed upfront and split equally
        amongst all members.
      </AccordionPanel>
    </AccordionItem>

    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            What should I know about taxes?
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <UnorderedList>
          <ListItem>
            <Text>
              Since Midway Capital will invest in multiple syndications, each
              deal will produce a separate
              <Link
                color="#6C63FF"
                href="https://www.investopedia.com/terms/s/schedule-k-1.asp"
              >
                {' Schedule K-1'}
              </Link>
              . Our accountant will file a
              <Link
                color="#6C63FF"
                href="https://www.irs.gov/forms-pubs/about-form-1065"
              >
                {' Form 1065 '}
              </Link>
              to distribute the profits to each co-investor based on their
              initial contributions. Real estate funds will have
              <Link
                color="#6C63FF"
                href="https://www.investopedia.com/articles/investing/060815/how-rental-property-depreciation-works.asp"
              >
                {' depreciation '}
              </Link>
              that will offset some of your gains so you can expect some paper
              losses to lower your taxable income in that current year.
            </Text>
          </ListItem>
          <ListItem>
            When/if cost-efficient we will consider combining K-1s for investors
            that are part of multiple deals.
          </ListItem>
          <ListItem>
            As some syndication K-1s may come in late, we all should expect to
            file for a tax extension every year. (It&apos;s free and super easy
            to do on
            <Link
              color="#6C63FF"
              href="https://www.irs.gov/forms-pubs/extension-of-time-to-file-your-tax-return"
            >
              {' IRS.gov'}
            </Link>
            )
          </ListItem>
        </UnorderedList>
      </AccordionPanel>
    </AccordionItem>
  </Accordion>
);

export default FaqAccordion;
